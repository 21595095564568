body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

div#root {
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow: hidden;
}
